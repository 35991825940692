import React, { useState } from "react";
import GroupingEdit from "../Editbuttons/Grouping";
import GroupingEditEvent from "../EditButtonsEvent/Grouping";
import GooglePlacesAutoComplete from "../GooglePlacesAutoComplete/GooglePlacesAutoComplete";
import LatLongBox from "../LatLongBox/LatLongBox";
import LocationNumber from "../LocationNumber/LocationNumber";
import { useSelector } from "react-redux";
// import { DisplaySettings } from "../../Store/Models";
import Plot from "../Plot/Plot";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function MapSection({ map }) {
  const [createdMarker, setCreatedMarker] = useState(null);
  const [createdPolygon, setCreatedPolygon] = useState(null);
  const [deletedMultiplePointsBoundary, setDeletedMultiplePointsBoundary] =
    useState(null);
  const [label, setLabel] = useState(false);

  const onCreatedMarker = (evMarker) => {
    if (evMarker) {
      setCreatedMarker(evMarker);
    }
  };

  const onCreatedPolygon = (evPolygon) => {
    if (evPolygon) {
      setCreatedPolygon(evPolygon);
    }
  };

  const onDeletedMultiplePointsBoundary = (evPolygon) => {
    if (evPolygon) {
      setDeletedMultiplePointsBoundary(evPolygon);
    }
  };
  const showEditbuttons = useSelector(
    (state) => state.DisplaySettings.showEditbuttons
  );
  const showEditbuttonsEvent = useSelector(
    (state) => state.DisplaySettings.showEditbuttonsEvent
  );
  const showLatLongBox = useSelector(
    (state) => state.DisplaySettings.showLatLongBox
  );
  const showMapEventLoaderGrid = useSelector(
    (state) => state.DisplaySettings.showMapEventLoaderGrid
  );
  const showMapEventLoaderDelete = useSelector(
    (state) => state.DisplaySettings.showMapEventLoaderDelete
  );

  return (
    <>
      <GroupingEdit
        map={map}
        display={showEditbuttons}
        onCreatedPolygon={onCreatedPolygon}
        onDeletedMultiplePointsBoundary={onDeletedMultiplePointsBoundary}
      />
      <GroupingEditEvent
        display={showEditbuttonsEvent}
        createdMarker={createdMarker}
        createdPolygon={createdPolygon}
        deletedMultiplePointsBoundary={deletedMultiplePointsBoundary}
      />
      <GooglePlacesAutoComplete map={map} />
      <Box
        sx={{
          position: "absolute",
          top: "8px",
          marginLeft: "400px",
          backgroundColor: "white",
          borderRadius: "5px",
          display: "block",
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={label} onChange={() => setLabel(!label)} />
            }
            label="Label"
          />
        </FormGroup>
      </Box>

      <LatLongBox
        display={showLatLongBox}
        map={map}
        onCreatedMarker={onCreatedMarker}
      />
      <LocationNumber />
      <Box
        sx={{
          position: "absolute",
          top: "30vh",
          marginLeft: "25vw",
          backgroundColor: "white",
          borderRadius: "5px",
          border: "2px solid black",
          display: showMapEventLoaderDelete ? "block" : "none",
          height: "400px",
          width: "400px",
        }}
      >
        <Typography>
          {" "}
          You are deleting multiple points either click Save or Cancel to
          proceed. Saving may take time . Please Wait until this dialog box
          disapper
        </Typography>
        <CircularProgress
          sx={{
            position: "relative",
            top: "30%",
            marginLeft: "40%",
            height: "200px",
            width: "200px",
          }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "50vh",
          marginLeft: "30vw",
          backgroundColor: "white",
          borderRadius: "5px",
          border: "2px solid black",
          display: showMapEventLoaderGrid ? "block" : "none",
          height: "200px",
          width: "200px",
        }}
      >
        <Typography> Making Grid.Please Wait.....</Typography>
        <CircularProgress
          sx={{
            position: "relative",
            top: "30%",
            marginLeft: "40%",
            height: "200px",
            width: "200px",
          }}
        />
      </Box>
      {map ? <Plot map={map} label={label} /> : null}
    </>
  );
}
