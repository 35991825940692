import React from "react";
import { BasePage } from "../Layout";
import {
  Routes,
  Route,
  HashRouter as Router,
  Navigate,
} from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import { useSelector } from "react-redux";
import Locations from "../Locations/Locations";

export default function BaseRoutes() {
  let auth = useSelector((state) => state.Auth);

  return (
    <Router>
      <Routes>
        {auth.user ? (
          <>
            <Route
              path="/*"
              element={
                <BasePage>
                  <ProtectedRoutes />
                </BasePage>
              }
            ></Route>
            <Route path="/locations" element={<Locations />} />
          </>
        ) : (
          <>
            <Route path="auth/*" element={<PublicRoutes />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </>
        )}
      </Routes>
    </Router>
  );
}
