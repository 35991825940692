import axios from "axios";
import { Constants } from "../Utils";
import { get } from "./BaseFunctions";

const getFieldTreatments = (
  fieldUId,
  nextStartDate,
  nextEndDate,
  prevStartDate,
  prevEndDate,
  mode
) => {
  return get(
    `treatment/field-treatments?fieldUId=${fieldUId}&nextStartDate=${nextStartDate}&nextEndDate=${nextEndDate}&mode=${mode}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
  );
};

const getFieldApplications = (
  fieldUId,
  startDate,
  endDate,
  treatmentId
) => {
  console.log(fieldUId);
  return get(
    `treatment/field-treatments/applications?fieldUId=${fieldUId}&startDate=${startDate}&endDate=${endDate}&treatmentId=${treatmentId}`
  );
};

const getPlantChartData = async (fieldUId, startDate, endDate) => {
  let res = await axios.get(
    `${Constants.URL}treatment/get-chart-data-in-a-field?fieldUId=${fieldUId}&startDate=${startDate}&endDate=${endDate}`
  );
  return res;
};


export {
  getFieldTreatments,
  getFieldApplications,
  getPlantChartData
};
