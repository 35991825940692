import React from "react";

export default function ExpandLessIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M6.707 15.707a1 1 0 1 1-1.414-1.414l6-6a1 1 0 0 1 1.383-.03l6 5.5a1 1 0 1 1-1.352 1.474l-5.294-4.853-5.323 5.323Z"
          fill={props.color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
