import * as React from "react";
import { Tooltip, IconButton } from "@mui/material";
import PolylineIcon from "@mui/icons-material/Polyline";
import { useDispatch, useSelector } from "react-redux";
import { LocationsServices } from "../../services";

export default function EditAPolygon() {
  const dispatch = useDispatch();
  const currentSelectedFieldId = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldId
  );
  const handleEditPolygonButton = () => {
    LocationsServices.getAllPolygonsOfAfields(currentSelectedFieldId)
      .then((res) => {
        const polygonGeoms = res.data.map((item) => {
          return {
            ...item,
            deleteMode: false,
            editable: false,
            editMode: true,
          };
        });
        if (polygonGeoms.length > 0) {
          dispatch.FieldDetails.setSelectedFieldPolygonGeoms(polygonGeoms);
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
    dispatch.DisplaySettings.toggleEditButtons(false);
  };
  return (
    <Tooltip title="Edit a Polygon">
      <IconButton
        onClick={handleEditPolygonButton}
        sx={{
          "&:hover": { bgcolor: "red" },
        }}
        aria-label="Edit a Polygon"
      >
        <PolylineIcon sx={{ color: "black" }} />
      </IconButton>
    </Tooltip>
  );
}
