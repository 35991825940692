import moment from "moment";
import { Constants } from ".";
import { FieldServices, PPPServices } from "../services";

const getLineChartData = async (mode, field, insect) => {
  const dates = getDatesbyMode(mode);
  const data = await getData(dates, insect, field, mode);
  return data;
};

const getDatesbyMode = (selectedChartMode) => {
  let nextStartDate = "";
  let nextEndDate = "";
  let prevStartDate = "";
  let prevEndDate = "";
  let proceed = false;
  if (selectedChartMode === Constants.THIS_MONTH) {
    nextStartDate = moment().startOf("month").format("YYYY-MM-DD");
    nextEndDate = moment().format("YYYY-MM-DD");
    prevStartDate = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    prevEndDate = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    proceed = true;
  } else if (selectedChartMode === Constants.LAST_MONTH) {
    nextStartDate = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    nextEndDate = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    prevStartDate = moment()
      .subtract(2, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    prevEndDate = moment()
      .subtract(2, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    proceed = true;
  } else if (selectedChartMode === Constants.LAST_TWO_MONTHS) {
    nextStartDate = moment()
      .subtract(2, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    nextEndDate = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    prevStartDate = moment()
      .subtract(4, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    prevEndDate = moment()
      .subtract(3, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    proceed = true;
  } else if (selectedChartMode === Constants.THREE_MONTHS) {
    nextStartDate = moment()
      .subtract(2, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    nextEndDate = moment().format("YYYY-MM-DD");
    prevStartDate = moment()
      .subtract(5, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    prevEndDate = moment()
      .subtract(3, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    proceed = true;
  } else if (selectedChartMode === Constants.SIX_MONTHS) {
    nextStartDate = moment()
      .subtract(5, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    nextEndDate = moment().format("YYYY-MM-DD");
    prevStartDate = moment()
      .subtract(11, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    prevEndDate = moment()
      .subtract(6, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    proceed = true;
  } else if (selectedChartMode === Constants.THIS_YEAR) {
    nextStartDate = moment().startOf("year").format("YYYY-MM-DD");
    nextEndDate = moment().format("YYYY-MM-DD");
    prevStartDate = moment()
      .subtract(1, "years")
      .startOf("year")
      .format("YYYY-MM-DD");
    prevEndDate = moment()
      .subtract(1, "years")
      .endOf("year")
      .format("YYYY-MM-DD");
    proceed = true;
  } else if (selectedChartMode === Constants.LAST_YEAR) {
    nextStartDate = moment()
      .subtract(1, "years")
      .format("YYYY-MM-DD");
    nextEndDate = moment()
      .format("YYYY-MM-DD");
    prevStartDate = moment()
      .subtract(2, "years")
      .format("YYYY-MM-DD");
    prevEndDate = moment()
      .subtract(1, "years")
      .format("YYYY-MM-DD");
    proceed = true;
  } else if (selectedChartMode === Constants.TWO_YEARS) {
    nextStartDate = moment()
      .subtract(2, "years")
      .format("YYYY-MM-DD");
    nextEndDate = moment()
      .subtract(1, "years")
      .format("YYYY-MM-DD");
    prevStartDate = moment()
      .subtract(4, "years")
      .format("YYYY-MM-DD");
    prevEndDate = moment()
      .subtract(3, "years")
      .format("YYYY-MM-DD");
    proceed = true;
  } else if (selectedChartMode === Constants.FOUR_YEARS) {
    nextStartDate = moment()
      .subtract(4, "years")
      .format("YYYY-MM-DD");
    nextEndDate = moment()
      .endOf("year")
      .format("YYYY-MM-DD");
    prevStartDate = moment()
      .subtract(8, "years")
      .format("YYYY-MM-DD");
    prevEndDate = moment()
      .subtract(5, "years")
      .format("YYYY-MM-DD");
    proceed = true;
  }
  if (proceed) {
    return { nextStartDate, nextEndDate, prevStartDate, prevEndDate };
  }
};

const getData = async (dates, insect, field, mode) => {
  const { nextStartDate, nextEndDate, prevStartDate, prevEndDate } = dates;
  let nextData = [];
  let previousData = [];
  let pppData = [];
  previousData = await getChangeRateOfAField(
    nextStartDate,
    nextEndDate,
    field,
    insect
  );

  let resData = await PPPServices.getPlantChartData(
    field,
    nextStartDate,
    nextEndDate
  );
  
  resData = resData?.data?.data || [];

  resData?.map((item) => {
    const date = moment(item.createdAt).format('DD-MM-YYYY');
    if(pppData[date]){
      pppData[date].push(item);
    }else{
      pppData[date] = [item];
    }
  });
  
  // if (mode !== "single")
  //   previousData = await getChangeRateOfAField(
  //     prevStartDate,
  //     prevEndDate,
  //     field,
  //     insect
  //   );
  return { nextData, previousData, pppData };
};

const getChangeRateOfAField = async (startDate, endDate, field, insect) => {
  let res = await FieldServices.getDateRangesData(
    field,
    startDate,
    endDate,
    insect
  );
  let rangeData = getAvgOfDateRange(res.data, startDate, endDate);
  return rangeData;
};

const getAvgOfDateRange = (data, startDate, endDate) => {
  let finalData = [];
  for (var i = 0; i < data.length; i++) {
    let _startDate = moment(data[i].startDate);
    let _endDate = moment(data[i].endDate);
    let daysInRange = _endDate.diff(_startDate, "days");
    daysInRange++;
    let rangeAvg = (data[i].sum ) / daysInRange;
    let days = getOverlappingDaysInTwoDateRanges(
      startDate,
      endDate,
      _startDate,
      _endDate,
      daysInRange
    );
    finalData = [
      ...finalData,
      {
        avg: parseFloat((rangeAvg / data[i].traps).toFixed(2)),
        sum: parseFloat((days * rangeAvg).toFixed(2)),
        startDate: data[i].startDate,
        endDate: data[i].endDate,
      },
    ];
  }
  return finalData;
};

const getOverlappingDaysInTwoDateRanges = (
  startDate,
  endDate,
  _startDate,
  _endDate,
  daysInRange
) => {
  let diffDays = 0;
  if (_startDate >= startDate && _endDate > endDate) {
    diffDays = _endDate.diff(endDate, "days");
    return daysInRange - diffDays;
  } else if (_startDate < startDate && _endDate <= endDate) {
    diffDays = startDate.diff(_startDate, "days");
    return daysInRange - diffDays;
  } else if (_startDate < startDate && _endDate > endDate) {
    return endDate.diff(startDate, "days");
  } else {
    return daysInRange;
  }
};

export { getLineChartData, getDatesbyMode };
