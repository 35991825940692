import React from "react";
import { Dates, SideBar, TopBar } from "../Components";

function BasePage({ children }) {
  return (
    <>
      <div className="flex overflow-auto">
        <div className="xl:w-1/12 lg:w-2/12 sticky top-0">
          <SideBar />
        </div>
        <div className="xl:w-11/12 lg:w-10/12 pl-5">
          <div className="sticky top-0 z-10 bg-white">
            <TopBar />
            <Dates />
          </div>
          <div className="bottom-scroll-content">{children}</div>
        </div>
      </div>
    </>
  );
}

export default BasePage;
