import axios from "axios";
import { Constants } from "../Utils";

const get = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Constants.URL}${url}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const post = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${Constants.URL}${url}`, { ...data })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const put = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${Constants.URL}${url}`, { ...data })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const _delete = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${Constants.URL}${url}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { get, post, put, _delete };
