import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useDispatch } from "react-redux";

export default function AddAPoint({ map }) {
  const dispatch = useDispatch();

  const handleAddPointButton = () => {
    dispatch.DisplaySettings.toggleLatLongBox(true);
    dispatch.DisplaySettings.toggleEditButtons(false);
  };

  return (
    <>
      <Tooltip title="Add a Point">
        <IconButton
          onClick={handleAddPointButton}
          sx={{
            "&:hover": { bgcolor: "red" },
          }}
          aria-label="Add a Point"
        >
          <MyLocationIcon sx={{ color: "black" }} />
        </IconButton>
      </Tooltip>
    </>
  );
}
