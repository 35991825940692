import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

export default function LatLongBox({ display, map, onCreatedMarker }) {
  const dispatch = useDispatch();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [marker, setMarker] = useState(null);
  const createdPolygon = useSelector(
    (state) => state.CreatePointandPolygon.createdPolygon
  );
  const currentLocationNumber = useSelector(
    (state) => state.CreatePointandPolygon.currentLocationNumber
  );
  const editedPoint = useSelector(
    (state) => state.CreatePointandPolygon.editedPoint
  );
  const editedPolygon = useSelector(
    (state) => state.CreatePointandPolygon.editedPolygon
  );
  const deletedPoint = useSelector(
    (state) => state.CreatePointandPolygon.deletedPoint
  );
  const deletedPolygon = useSelector(
    (state) => state.CreatePointandPolygon.deletedPolygon
  );
  const deletedMultiplePointsBoundary = useSelector(
    (state) => state.CreatePointandPolygon.deletedMultiplePointsBoundary
  );

  useEffect(() => {
    if (!display) {
      setLatitude("");
      setLongitude("");
      setMarker(null);
    }
  }, [display]);

  useEffect(() => {
    if (marker) {
      marker.setPosition(new window.google.maps.LatLng(latitude, longitude));
      dispatch.DisplaySettings.toggleEditButtonsEvent(true);
      dispatch.CreatePointandPolygon.setCreatedPoint(marker);
      onCreatedMarker(marker);
    } else if (latitude && longitude) {
      const latLng = new window.google.maps.LatLng(latitude, longitude);
      const newMarker = new window.google.maps.Marker({
        position: latLng,
        map: map,
      });
      setMarker(newMarker);
    } else if (
      createdPolygon ||
      // editedPoint ||
      editedPolygon ||
      deletedPoint ||
      deletedPolygon ||
      deletedMultiplePointsBoundary
    ) {
      dispatch.DisplaySettings.toggleEditButtonsEvent(true);
    } else if (currentLocationNumber) {
      dispatch.DisplaySettings.toggleEditButtonsEvent(true);
    } else {
      dispatch.DisplaySettings.toggleEditButtonsEvent(false);
      dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
    }
  }, [
    latitude,
    longitude,
    currentLocationNumber,
    map,
    marker,
    dispatch.CreatePointandPolygon,
    dispatch.DisplaySettings,
    onCreatedMarker,
    createdPolygon,
    editedPoint,
    editedPolygon,
    deletedPoint,
    deletedPolygon,
    deletedMultiplePointsBoundary,
  ]);

  // Add a click event listener to the map
  useEffect(() => {
    if (map && display) {
      const clickListener = map.addListener("click", (event) => {
        var plusCursor =
          'url("https://maps.gstatic.com/mapfiles/crosshair.cur"), crosshair';
        map.getDiv().style.cursor = "crosshair";

        const clickedLat = event.latLng.lat();
        const clickedLng = event.latLng.lng();
        setLatitude(clickedLat);
        setLongitude(clickedLng);
      });

      // Clean up the listener when the component unmounts
      return () => {
        window.google.maps.event.removeListener(clickListener);
      };
    }
  }, [map, display]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "8px",
        marginLeft: "400px",
        backgroundColor: "white",
        borderRadius: "5px",
        display: display ? "block" : "none",
        height: "80px",
        padding: "10px",
      }}
    >
      <TextField
        id="latitude"
        label="Latitude"
        value={latitude}
        onChange={(event) => {
          setLatitude(event.target.value);
        }}
        InputProps={{ readOnly: false }}
        InputLabelProps={{
          shrink: true,
          position: "above",
          color: "primary",
        }}
      />
      <TextField
        id="longitude"
        label="Longitude"
        value={longitude}
        onChange={(event) => {
          setLongitude(event.target.value);
        }}
        InputProps={{ readOnly: false }}
        InputLabelProps={{
          shrink: true,
          position: "above",
          color: "primary",
        }}
      />
    </Box>
  );
}
