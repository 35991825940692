const PPP = {
  state: {
    treatments: [],
    seasonId: null,
    selectedTreatment: null,
    chartData: [],
    chartDate: false,
  },
  reducers: {
    SetTreatments: (state, payload) => {
      return {
        ...state,
        treatments: payload.treatments,
      };
    },
    SetSelectedTreatment: (state, payload) => {
      return {
        ...state,
        selectedTreatment: payload,
      };
    },
    SetChartData: (state, payload) => {
      return {
        ...state,
        chartData: payload,
      };
    },
    SetChartDate: (state, payload) => {
      return {
        ...state,
        chartDate: payload,
      };
    },
    Reset: (state, payload) => {
      return {
        treatments: [],
        seasonId: null,
        selectedTreatment: null,
        chartData: [],
        chartDate: false,
      };
    },
  },
  effects: (dispatch) => ({}),
};
export default PPP;
