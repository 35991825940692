import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../Components";
import { LoginServices } from "../../services";
import { setAuthToken, setupAxios } from "../../Utils";

function Login() {
  let [formData, setFormData] = useState({ username: "", password: "" });
  let [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const tryLogin = () => {
    setIsLoading(true);
    LoginServices.LoginFunction(formData)
      .then((res) => {
        let token = res.data.token.access_token;
        let user = res.data.e_user;
        localStorage.setItem("access_token", token);
        localStorage.setItem("user", JSON.stringify(user));
        setAuthToken(token);
        setupAxios(axios);
        setIsLoading(false);
        dispatch.Auth.Login({ token, user });
        navigate("/home");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onchange = (e) => {
    var name = e.target.name;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: e.target.value,
    }));
  };

  return (
    <section className="h-screen">
      <div className="px-6 h-full text-gray-800">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
          <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="w-full"
              alt="Sample image"
            />
          </div>
          <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            <form>
              <div className="flex flex-row items-center justify-center lg:justify-start">
                <p className="text-3xl mr-4 mb-5">Sign in to Bug Mapper</p>
              </div>
              <div className="mb-6">
                <input
                  type="text"
                  value={formData.username}
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlInput2"
                  placeholder="Username or email"
                  onChange={onchange}
                  name="username"
                />
              </div>

              <div className="mb-6">
                <input
                  type="password"
                  value={formData.password}
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlInput2"
                  onChange={onchange}
                  placeholder="Password"
                  name="password"
                />
              </div>

              <div className="text-center lg:text-left">
                <button
                  onClick={tryLogin}
                  type="button"
                  className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  {isLoading ? (
                    <Spinner color="#fff" size={20} className="mx-5" />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
