const Insects = {
  state: {
    selected: null,
    selectedDisease: null,
    data: null,
    diseases: null,
  },
  reducers: {
    Select: (state, payload) => {
      return {
        ...state,
        selected: payload,
        selectedDisease: null
      };
    },
    SelectDisease: (state, payload) => {
      return {
        ...state,
        selectedDisease: payload,
        selected: null
      };
    },
    SetInsects: (state, payload) => {
      return {
        ...state,
        selected: payload.selected,
        selectedDisease: !payload.selected ? state.selectedDisease : null,
        data: payload.data,
      };
    },
    Reset: (state, payload) => {
      return {
        selected: null,
        selectedDisease: null,
        data: null,
      };
    },
  },
  effects: (dispatch) => ({}),
};
export default Insects;
