const Auth = {
  state: {
    user: null,
    token: null,
  },
  reducers: {
    Login: (state, payload) => {
      return {
        ...state,
        user: payload.user,
        token: payload.token,
      };
    },
    Logout: (state, payload) => {
      return {
        user: null,
        token: null,
      };
    },
  },
  effcets: (dispatch) => ({}),
};

export default Auth;
