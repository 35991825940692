const AppSettings = {
  state: {
    selectedMode: "single",
    selectedChartMode: "LAST_YEAR",
    selectedPage: "Home",
    action: "sum",
    zoom: 12,
    layerMode: "color", //simple
    dates: {
      previousDate: {
        startDate: "",
        endDate: "",
      },
      nextDate: {
        startDate: "",
        endDate: "",
      },
    },
    initialLoad: true
  },
  reducers: {
    SelectMode: (state, payload) => {
      return {
        ...state,
        selectedMode: payload,
      };
    },
    SelectChartMode: (state, payload) => {
      return {
        ...state,
        selectedChartMode: payload,
      };
    },
    SetPage: (state, payload) => {
      return {
        ...state,
        selectedPage: payload,
      };
    },
    SetAction: (state, payload) => {
      return {
        ...state,
        action: payload,
      };
    },
    SetZoom: (state, payload) => {
      return {
        ...state,
        zoom: payload,
      };
    },
    SetLayerMode: (state, payload) => {
      return {
        ...state,
        layerMode: payload,
      };
    },
    SetPreviousDates: (state, payload) => {
      return {
        ...state,
        dates: {
          previousDate: payload,
          nextDate: state.dates.nextDate,
        },
      };
    },
    SetNextDates: (state, payload) => {
      return {
        ...state,
        dates: {
          previousDate: state.dates.previousDate,
          nextDate: payload,
        },
      };
    },
    Reset: (state, payload) => {
      return {
        selectedMode: "single",
        selectedChartMode: "LAST_YEAR",
        selectedPage: "Home",
        action: "sum",
        zoom: 12,
        layerMode: "color",
        dates: {
          previousDate: {
            startDate: "",
            endDate: "",
          },
          nextDate: {
            startDate: "",
            endDate: "",
          },
        },
        initialLoad: true
      };
    },
    SetIntialLoad: (state, payload) => {
      return {
        ...state,
        initialLoad: payload
      };
    },
  },
  effects: (dispatch) => ({}),
};
export default AppSettings;
