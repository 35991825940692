import * as React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import "./Grouping.css";
import AddAPoint from "./CreatePoint";
import AddAPolygon from "./CreatePolygon";
import EditAPoint from "./EditPoint";
import EditAPolygon from "./EditPolygon";
import DeleteAPoint from "./DeletePoint";
import DeleteAPolygon from "./DeletePolygon";
import DeleteMultiplePoints from "./DeleteMultiplePoints";
import MoveMultiplePoints from "./MoveMultiplePoints";

export default function BasicButtonGroup({
  map,
  display,
  onCreatedPolygon,
  onDeletedMultiplePointsBoundary,
}) {
  return (
    <ButtonGroup
      sx={{ display: display ? "block" : "none" }}
      className="grouping-edit-buttons"
      variant="contained"
      aria-label="outlined primary button group"
    >
      <AddAPoint map={map} />
      <AddAPolygon map={map} onCreatedPolygon={onCreatedPolygon} />
      <EditAPoint map={map} />
      <EditAPolygon />
      <DeleteAPoint />
      <DeleteAPolygon />
      <DeleteMultiplePoints
        map={map}
        onDeletedMultiplePointsBoundary={onDeletedMultiplePointsBoundary}
      />
      <MoveMultiplePoints />
    </ButtonGroup>
  );
}
