import classNames from "classnames";
import React from "react";

function Image({ height, width, src, alt, className = [] }) {
  return (
    <img
      className={classNames("pt-1", className)}
      style={{ height: height, width: width }}
      src={src}
      alt={alt}
    />
  );
}

export default Image;
