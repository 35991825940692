import React, { useEffect } from "react";
import CustomAutoComplete from "../CustomAutoComplete/CustomAutoComplete";
import FieldCard from "../FieldCard/FieldCard";
import MakeGrid from "../MakeGrid/MakeGrid";
import { useSelector } from "react-redux";
import { CircularProgress, Box, Grid, Typography } from "@mui/material";

function SideBar({ map }) {
  const showMakeGrid = useSelector(
    (state) => state.DisplaySettings.showMakeGrid
  );
  const showFieldCard = useSelector(
    (state) => state.DisplaySettings.showFieldCard
  );
  const loadingFields = useSelector((state) => state.Loaders.loadingFields);
  const fields = useSelector((state) => state.CompanyFields.fields);

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        height: "100vh",
        paddingTop: "0px",
        overflowY: "auto",
      }}
    >
      <CustomAutoComplete />
      {fields.length > 0 && showFieldCard
        ? fields.map((_, index) => (
            <FieldCard key={index} index={index} _={_} map={map} />
          ))
        : null}
      {loadingFields ? (
        <Box sx={{ height: "100vh" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ height: "30%" }}
          >
            <CircularProgress />
          </Grid>
        </Box>
      ) : null}
      {!fields.length > 0 && showFieldCard ? (
        <Box sx={{ height: "100vh", margin: "10px" }}>
          <Typography>Please select a company to load the fields</Typography>
        </Box>
      ) : null}

      {showMakeGrid ? <MakeGrid map={map} /> : null}
    </div>
  );
}

export default SideBar;
