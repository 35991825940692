import * as React from "react";
import { Tooltip, IconButton } from "@mui/material";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import { LocationsServices } from "../../services";
import { useDispatch, useSelector } from "react-redux";

export default function DeleteAPolygon() {
  const dispatch = useDispatch();
  const currentSelectedFieldId = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldId
  );
  const handleDeletePolygonButton = () => {
    LocationsServices.getAllPolygonsOfAfields(currentSelectedFieldId)
      .then((res) => {
        const polygonGeoms = res.data.map((item) => {
          return {
            ...item,
            deleteMode: true,
            editable: false,
            editMode: false,
          };
        });
        if (polygonGeoms.length > 0) {
          dispatch.FieldDetails.setSelectedFieldPolygonGeoms(polygonGeoms);
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
    dispatch.DisplaySettings.toggleEditButtons(false);
  };
  return (
    <Tooltip title="Delete a Polygon">
      <IconButton
        onClick={handleDeletePolygonButton}
        sx={{
          "&:hover": { bgcolor: "red" },
        }}
        aria-label="Delete a Point"
      >
        <FolderDeleteIcon sx={{ color: "black" }} />
      </IconButton>
    </Tooltip>
  );
}
