import { useState } from "react";

function useLoading() {
  let [isLoading, setIsLoading] = useState(false);
  function ChangeState() {
    setIsLoading((prevState) => !prevState);
  }
  return [isLoading, ChangeState];
}

export default useLoading;
