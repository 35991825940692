const Loaders = {
  state: {
    loadingFields: false,
  },
  reducers: {
    // To change the state fields
    toggleLoadingFields: (state) => {
      return {
        ...state,
        loadingFields: !state.loadingFields,
      };
    },
  },
  effcets: (dispatch) => ({}),
};

export default Loaders;
