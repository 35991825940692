import React from "react";
import { Icons } from "../../Assets";

export default function ColumnHeader({
  width,
  name,
  sort,
  select,
  columnName,
  onClick,
  type,
}) {
  return (
    <th width={width} onClick={() => onClick(columnName, type)}>
      <div style={{ display: "flex" }}>
        {name}
        <div
          className="d-flex"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            flex: 1,
          }}
        >
          <Icons.ExpandLessIcon
            style={{
              marginTop: "-3px",
            }}
            color={
              sort === true && select.includes(columnName) ? "#fff" : "#898f8b"
            }
          />
          <Icons.ExpandMoreIcon
            style={{
              marginTop: "-8px",
            }}
            color={
              sort === false && select.includes(columnName) ? "#fff" : "#898f8b"
            }
          />
        </div>
      </div>
    </th>
  );
}
