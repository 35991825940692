import React, { useState, useEffect } from "react";
import {
  Box,
  Tooltip,
  IconButton,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FloatInput from "./FloatInput";
import { useSelector, useDispatch } from "react-redux";
import { LocationsServices } from "../../services";
import cross_icon from "../Plot/cross_alp.png";

function incrementString(value) {
  let carry = 1;
  let res = "";

  for (let i = value.length - 1; i >= 0; i--) {
    let char = value.toUpperCase().charCodeAt(i);

    char += carry;

    if (char > 90) {
      char = 65;
      carry = 1;
    } else {
      carry = 0;
    }

    res = String.fromCharCode(char) + res;

    if (!carry) {
      res = value.substring(0, i) + res;
      break;
    }
  }

  if (carry) {
    res = "A" + res;
  }

  return res;
}

// const google = window.google;
const icon_ = {
  path: "M5.5 7h5v1h-5v5h-1v-5h-5v-1h5v-5h1v5z",
  fillColor: "#0077FF",
  fillOpacity: 1,
  scale: 1,
  strokeColor: "red",
  strokeWeight: 1,
};
export default function MakeGrid({ map }) {
  const dispatch = useDispatch();
  const currentSelectedFieldUID = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldUID
  );
  const selectedFieldCoords = useSelector(
    (state) => state.FieldDetails.selectedFieldCoords
  );

  // for maker 1
  const [marker, setMarker] = useState();
  const [highlighted, setHighlighted] = useState(false);
  const [latLng, setLatLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [mapClickEvent, setMapClickEvent] = useState(null);

  // for marker 2
  const [marker2, setMarker2] = useState();
  const [highlighted2, setHighlighted2] = useState(false);
  const [latLng2, setLatLng2] = useState(null);
  const [lat2, setLat2] = useState(null);
  const [lng2, setLng2] = useState(null);
  const [mapClickEvent2, setMapClickEvent2] = useState(null);

  // for marker 3
  const [marker3, setMarker3] = useState();
  const [highlighted3, setHighlighted3] = useState(false);
  const [latLng3, setLatLng3] = useState(null);
  const [lat3, setLat3] = useState(null);
  const [lng3, setLng3] = useState(null);
  const [mapClickEvent3, setMapClickEvent3] = useState(null);

  // for marker 4
  const [marker4, setMarker4] = useState();
  const [highlighted4, setHighlighted4] = useState(false);
  const [latLng4, setLatLng4] = useState(null);
  const [lat4, setLat4] = useState(null);
  const [lng4, setLng4] = useState(null);
  const [mapClickEvent4, setMapClickEvent4] = useState(null);

  const [markers, setMarkers] = useState([]);
  const [displaySave, setDisplaySave] = useState("none");
  const [saving, setSaving] = useState(false);

  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  // *****************************************************************

  const [finalGrid, setFinalGrid] = useState();
  const [finalLabel, setFinalLabel] = useState();

  // for marker 1
  useEffect(() => {
    if (latLng) {
      map.panTo(latLng);
      marker.setPosition(latLng);
    }
  }, [latLng, map, marker]);

  // for marker 2
  useEffect(() => {
    if (latLng2) {
      map.panTo(latLng2);
      marker2.setPosition(latLng2);
    }
  }, [latLng2, map, marker2]);

  // for marker 3
  useEffect(() => {
    if (latLng3) {
      map.panTo(latLng3);
      marker3.setPosition(latLng3);
    }
  }, [latLng3, map, marker3]);

  // for marker 4
  useEffect(() => {
    if (latLng4) {
      map.panTo(latLng4);
      marker4.setPosition(latLng4);
    }
  }, [latLng4, map, marker4]);

  // *****************************************************************

  // for marker 1
  const handleMapClick = (event) => {
    const newLatLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setLatLng(newLatLng);
    setLat(newLatLng.lat.toString());
    setLng(newLatLng.lng.toString());
  };

  // for marker 2
  const handleMapClick2 = (event) => {
    const newLatLng2 = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setLatLng2(newLatLng2);
    setLat2(newLatLng2.lat.toString());
    setLng2(newLatLng2.lng.toString());
  };

  // for marker 3
  const handleMapClick3 = (event) => {
    const newLatLng3 = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setLatLng3(newLatLng3);
    setLat3(newLatLng3.lat.toString());
    setLng3(newLatLng3.lng.toString());
  };

  // for marker 4
  const handleMapClick4 = (event) => {
    const newLatLng4 = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setLatLng4(newLatLng4);
    setLat4(newLatLng4.lat.toString());
    setLng4(newLatLng4.lng.toString());
  };

  // *****************************************************************

  // for maker 1
  const handleButtonClick = () => {
    setHighlighted2(false);
    setHighlighted3(false);
    setHighlighted4(false);
    if (!highlighted) {
      setHighlighted(true);
      const event = map.addListener("click", handleMapClick);
      setMapClickEvent(event);
      window.google.maps.event.removeListener(mapClickEvent2);
      window.google.maps.event.removeListener(mapClickEvent3);
      window.google.maps.event.removeListener(mapClickEvent4);
    } else {
      setHighlighted(false);
      window.google.maps.event.removeListener(mapClickEvent);
    }
  };

  // for marker 2
  const handleButtonClick2 = () => {
    setHighlighted(false);
    setHighlighted3(false);
    setHighlighted4(false);
    if (!highlighted2) {
      setHighlighted2(true);
      const event2 = map.addListener("click", handleMapClick2);
      setMapClickEvent2(event2);
      window.google.maps.event.removeListener(mapClickEvent);
      window.google.maps.event.removeListener(mapClickEvent3);
      window.google.maps.event.removeListener(mapClickEvent4);
    } else {
      setHighlighted2(false);
      window.google.maps.event.removeListener(mapClickEvent2);
    }
  };

  // for marker 3
  const handleButtonClick3 = () => {
    setHighlighted(false);
    setHighlighted2(false);
    setHighlighted4(false);
    if (!highlighted3) {
      setHighlighted3(true);
      const event3 = map.addListener("click", handleMapClick3);
      setMapClickEvent3(event3);
      window.google.maps.event.removeListener(mapClickEvent);
      window.google.maps.event.removeListener(mapClickEvent2);
      window.google.maps.event.removeListener(mapClickEvent4);
    } else {
      setHighlighted3(false);
      window.google.maps.event.removeListener(mapClickEvent3);
    }
  };

  // for marker 4
  const handleButtonClick4 = () => {
    setHighlighted(false);
    setHighlighted2(false);
    setHighlighted3(false);
    if (!highlighted4) {
      setHighlighted4(true);
      const event4 = map.addListener("click", handleMapClick4);
      setMapClickEvent4(event4);
      window.google.maps.event.removeListener(mapClickEvent);
      window.google.maps.event.removeListener(mapClickEvent2);
      window.google.maps.event.removeListener(mapClickEvent3);
    } else {
      setHighlighted4(false);
      window.google.maps.event.removeListener(mapClickEvent4);
    }
  };

  // *****************************************************************

  // for maker 1
  const handleLatChange = (event) => {
    setLat(event.target.value);
    if (event.target.value && lng) {
      const newLatLng = {
        lat: parseFloat(event.target.value),
        lng: parseFloat(lng),
      };
      setLatLng(newLatLng);
      marker.setPosition(newLatLng);
      map.panTo(newLatLng);
    }
  };

  // for marker 2
  const handleLatChange2 = (event) => {
    setLat2(event.target.value);
    if (event.target.value && lng2) {
      const newLatLng2 = {
        lat: parseFloat(event.target.value),
        lng: parseFloat(lng2),
      };
      setLatLng2(newLatLng2);
      marker2.setPosition(newLatLng2);
      map.panTo(newLatLng2);
    }
  };

  // for marker 3
  const handleLatChange3 = (event) => {
    setLat3(event.target.value);
    if (event.target.value && lng3) {
      const newLatLng3 = {
        lat: parseFloat(event.target.value),
        lng: parseFloat(lng3),
      };
      setLatLng3(newLatLng3);
      marker3.setPosition(newLatLng3);
      map.panTo(newLatLng3);
    }
  };

  // for marker 4
  const handleLatChange4 = (event) => {
    setLat4(event.target.value);
    if (event.target.value && lng4) {
      const newLatLng4 = {
        lat: parseFloat(event.target.value),
        lng: parseFloat(lng4),
      };
      setLatLng4(newLatLng4);
      marker4.setPosition(newLatLng4);
      map.panTo(newLatLng4);
    }
  };

  // *****************************************************************

  // for marker 1
  const handleLngChange = (event) => {
    setLng(event.target.value);
    if (event.target.value && lat) {
      const newLatLng = {
        lat: parseFloat(lat),
        lng: parseFloat(event.target.value),
      };
      setLatLng(newLatLng);
      marker.setPosition(newLatLng);
      map.panTo(newLatLng);
    }
  };

  // for marker 2
  const handleLngChange2 = (event) => {
    setLng2(event.target.value);
    if (event.target.value && lat2) {
      const newLatLng2 = {
        lat: parseFloat(lat2),
        lng: parseFloat(event.target.value),
      };
      setLatLng2(newLatLng2);
      marker2.setPosition(newLatLng2);
      map.panTo(newLatLng2);
    }
  };

  // for marker 3
  const handleLngChange3 = (event) => {
    setLng3(event.target.value);
    if (event.target.value && lat3) {
      const newLatLng3 = {
        lat: parseFloat(lat3),
        lng: parseFloat(event.target.value),
      };
      setLatLng3(newLatLng3);
      marker3.setPosition(newLatLng3);
      map.panTo(newLatLng3);
    }
  };
  // for marker 4
  const handleLngChange4 = (event) => {
    setLng4(event.target.value);
    if (event.target.value && lat4) {
      const newLatLng4 = {
        lat: parseFloat(lat4),
        lng: parseFloat(event.target.value),
      };
      setLatLng4(newLatLng4);
      marker4.setPosition(newLatLng4);
      map.panTo(newLatLng4);
    }
  };

  // *****************************************************************

  // for marker all
  useEffect(() => {
    if (map) {
      map.setOptions({ draggableCursor: "crosshair" });
      const imageWidth = 32;
      const imageHeight = 32;

      const icon = new window.google.maps.MarkerImage(
        cross_icon,
        new window.google.maps.Size(imageWidth, imageHeight),
        new window.google.maps.Point(0, 0),
        new window.google.maps.Point(16, 16),
        new window.google.maps.Size(imageWidth, imageHeight)
      );

      const newMarker = new window.google.maps.Marker({
        icon: icon,
        map: map,
      });
      const newMarker2 = new window.google.maps.Marker({
        icon: icon,
        map: map,
      });
      const newMarker3 = new window.google.maps.Marker({
        icon: icon,
        map: map,
      });
      const newMarker4 = new window.google.maps.Marker({
        icon: icon,
        map: map,
      });
      setMarker(newMarker);
      setMarker2(newMarker2);
      setMarker3(newMarker3);
      setMarker4(newMarker4);
    }
  }, [map]);

  const handleClickOnBack = () => {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null); // remove the marker from the map
    }
    setMarkers([]); // clear the array
    dispatch.DisplaySettings.toggleMakeGrid();
    dispatch.DisplaySettings.toggleFieldCard();

    marker.setMap(null);
    marker2.setMap(null);
    marker3.setMap(null);
    marker4.setMap(null);
    setSaving(false);
    dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
  };

  const handleCreateGrid = (e) => {
    e.preventDefault();
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null); // remove the marker from the map
    }
    setMarkers([]); // clear the array
    const allMarkers = [];
    const numRows = rows;
    const numCols = columns;
    const point1 = [latLng4.lat, latLng4.lng];
    const point2 = [latLng.lat, latLng.lng];
    const point3 = [latLng3.lat, latLng3.lng];
    const point4 = [latLng2.lat, latLng2.lng];

    const linSpace = (s, e, numElem) => {
      let elems = [];
      for (let j = 0; j < numElem; j++) {
        let val = s + ((e - s) / (numElem - 1)) * j;
        val = val.toFixed(8);
        elems.push(val);
      }
      return elems;
    };

    let start_i = point1[0];
    let end_i = point2[0];
    let start_j = point1[1];
    let end_j = point2[1];
    let rows_ = [];

    for (let i = 0; i < numRows; i++) {
      let rowI = linSpace(start_i, end_i, numCols);
      let rowJ = linSpace(start_j, end_j, numCols);
      let row = [];
      for (let j = 0; j < numCols; j++) {
        row.push([rowI[j], rowJ[j]]);
      }
      rows_.push(row);
      start_i = start_i + (point3[0] - point1[0]) / (numRows - 1);
      end_i = end_i + (point4[0] - point2[0]) / (numRows - 1);
      start_j = start_j + (point3[1] - point1[1]) / (numRows - 1);
      end_j = end_j + (point4[1] - point2[1]) / (numRows - 1);
    }

    const outputArray = [];

    let parsedInt;
    let stringPart;
    if (selectedFieldCoords !== null) {
      let latest_location = 0;
      selectedFieldCoords.map((item) => {
        const matches = item.location_no.match(/^(\d+)([A-Za-z]+)$/);
        if (matches) {
          const integerPart = parseInt(matches[1], 10);
          if (latest_location < integerPart) {
            latest_location = integerPart;
            stringPart = matches[2];
          }
        }
      });
      parsedInt = latest_location;
    } else {
      parsedInt = 0;
      stringPart = "";
    }

    let string = "";
    for (let i = 0; i < rows_.length; i++) {
      const row = [];
      string = incrementString(string);
      for (let j = rows_[i].length; j > 0; j--) {
        const columnValue = j;
        const stringValue = `${parsedInt + columnValue}${string}`;
        row.push(stringValue);
      }
      outputArray.push(row);
    }

    for (let i = 0; i < rows_.length; i++) {
      const row = rows_[i];
      const labelRow = outputArray[i];

      for (let j = 0; j < row.length; j++) {
        const coordinate = {
          lat: parseFloat(row[j][0]),
          lng: parseFloat(row[j][1]),
        };

        const markerLabel = labelRow[j];

        // Define the marker
        const marker = new window.google.maps.Marker({
          position: coordinate,
          map: map,
          // icon: icon_,
          // label: markerLabel,
        });

        marker.setLabel({
          text: markerLabel,
          color: "#00FFFF",
        });
        allMarkers.push(marker);
      }
    }
    setFinalGrid(rows_);
    setFinalLabel(outputArray);
    setMarkers(allMarkers);
    if (rows) {
      setDisplaySave("block");
    }
  };

  const handleClickOnSave = () => {
    setSaving(true);
  };

  useEffect(() => {
    if (saving) {
      startSavingPoint();
    }
  }, [saving]);

  const startSavingPoint = async () => {
    if (
      finalGrid &&
      finalGrid.length > 0 &&
      finalLabel &&
      finalLabel.length > 0
    ) {
      var batch = [];
      for (let i = 0; i < finalGrid.length; i++) {
        const row = finalGrid[i];
        const labelRow = finalLabel[i];

        for (let j = 0; j < row.length; j++) {
          const coordinate = {
            lat: parseFloat(row[j][0]),
            lng: parseFloat(row[j][1]),
          };

          const markerLabel = labelRow[j];
          const currentDate = new Date();
          const formattedDate = currentDate.toLocaleString();

          batch.push({
            no: markerLabel,
            title: formattedDate,
            wkt: `POINT(${coordinate.lat} ${coordinate.lng})`,
          });

          if(batch.length === 100){
            const data = {
              field_uid: currentSelectedFieldUID,
              points: batch
            };
            await LocationsServices.postPointField(data);
            batch = [];
          }
          
        }
      }

      if(batch.length > 0){
        const data = {
          field_uid: currentSelectedFieldUID,
          points: batch
        };
        await LocationsServices.postPointField(data);
      }
    }
    dispatch.DisplaySettings.toggleMakeGrid();
    marker.setMap(null);
    marker2.setMap(null);
    marker3.setMap(null);
    marker4.setMap(null);
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null); // remove the marker from the map
    }
    setMarkers([]); // clear the array
    setSaving(false);
    dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
    setFinalGrid();
    setFinalLabel();
    // dispatch.DisplaySettings.toggleMapEventLoaderGrid(true);
    setTimeout(() => {
      LocationsServices.getAllCoordinatesOfAfields(currentSelectedFieldUID)
        .then((res) => {
          const fieldCoords = res.data.locations;
          const fieldCoords_updated = fieldCoords.map((item) => {
            return {
              ...item,
              editable: false,
              deleteMode: false,
              editMode: false,
            };
          });
          if (fieldCoords_updated.length > 0) {
            dispatch.FieldDetails.setSelectedFieldCoords(fieldCoords_updated);
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
      // dispatch.DisplaySettings.toggleMapEventLoaderGrid(false);
    }, 10000);
  }

  const handleRowChange = (e) => {
    setRows(e.target.value);
  };

  const handleColumnChange = (e) => {
    setColumns(e.target.value);
  };

  return (
    <form onSubmit={handleCreateGrid}>
      <Box
        sx={{
          //   position: "absolute",
          //   bottom: "8px",
          //   marginLeft: "200px",
          margin: "10px",
          backgroundColor: "#f5f5f5",
          borderRadius: "5px",
          display: "flex",
        }}
      >
        Make a Points Here for field : {currentSelectedFieldUID}
      </Box>
      <Box
        sx={{
          //   position: "absolute",
          //   bottom: "8px",
          //   marginLeft: "200px",
          margin: "10px",
          backgroundColor: "#f5f5f5",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            margin: "10px",
            backgroundColor: "white",
            borderRadius: "5px",
            display: "flex",
          }}
        >
          <FloatInput
            id="latitude"
            label="Latitude"
            InputProps={{ readOnly: highlighted }}
            InputLabelProps={{
              shrink: true,
              position: "above",
              color: "primary",
            }}
            value={lat}
            onChange={handleLatChange}
          />
          <FloatInput
            id="longitude"
            label="Longitude"
            InputProps={{ readOnly: highlighted }}
            InputLabelProps={{
              shrink: true,
              position: "above",
              color: "primary",
            }}
            value={lng}
            onChange={handleLngChange}
          />
          <Tooltip title="Add first Point">
            <IconButton
              onClick={handleButtonClick}
              aria-label="Add First Point"
            >
              <AddCircleIcon sx={{ color: highlighted ? "red" : "green" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            margin: "10px",
            backgroundColor: "white",
            borderRadius: "5px",
            display: "flex",
          }}
        >
          <FloatInput
            id="latitude"
            label="Latitude"
            InputProps={{ readOnly: highlighted2 }}
            InputLabelProps={{
              shrink: true,
              position: "above",
              color: "primary",
            }}
            value={lat2}
            onChange={handleLatChange2}
          />
          <FloatInput
            id="longitude"
            label="Longitude"
            InputProps={{ readOnly: highlighted2 }}
            InputLabelProps={{
              shrink: true,
              position: "above",
              color: "primary",
            }}
            value={lng2}
            onChange={handleLngChange2}
          />
          <Tooltip title="Add first Point">
            <IconButton
              onClick={handleButtonClick2}
              aria-label="Add First Point"
            >
              <AddCircleIcon sx={{ color: highlighted2 ? "red" : "green" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            margin: "10px",
            backgroundColor: "white",
            borderRadius: "5px",
            display: "flex",
          }}
        >
          <FloatInput
            id="latitude"
            label="Latitude"
            InputProps={{ readOnly: highlighted3 }}
            InputLabelProps={{
              shrink: true,
              position: "above",
              color: "primary",
            }}
            value={lat3}
            onChange={handleLatChange3}
          />
          <FloatInput
            id="longitude"
            label="Longitude"
            InputProps={{ readOnly: highlighted3 }}
            InputLabelProps={{
              shrink: true,
              position: "above",
              color: "primary",
            }}
            value={lng3}
            onChange={handleLngChange3}
          />
          <Tooltip title="Add first Point">
            <IconButton
              onClick={handleButtonClick3}
              aria-label="Add First Point"
            >
              <AddCircleIcon sx={{ color: highlighted3 ? "red" : "green" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            margin: "10px",
            backgroundColor: "white",
            borderRadius: "5px",
            display: "flex",
          }}
        >
          <FloatInput
            id="latitude"
            label="Latitude"
            InputProps={{ readOnly: highlighted4 }}
            InputLabelProps={{
              shrink: true,
              position: "above",
              color: "primary",
            }}
            value={lat4}
            onChange={handleLatChange4}
          />
          <FloatInput
            id="longitude"
            label="Longitude"
            InputProps={{ readOnly: highlighted4 }}
            InputLabelProps={{
              shrink: true,
              position: "above",
              color: "primary",
            }}
            value={lng4}
            onChange={handleLngChange4}
          />
          <Tooltip title="Add first Point">
            <IconButton
              onClick={handleButtonClick4}
              aria-label="Add First Point"
            >
              <AddCircleIcon sx={{ color: highlighted4 ? "red" : "green" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "10px",
          backgroundColor: "white",
          borderRadius: "5px",
          display: "flex",
        }}
      >
        <TextField
          required={true}
          type="number"
          id="rows"
          label="rows"
          InputProps={{ readOnly: false }}
          InputLabelProps={{
            shrink: true,
            position: "above",
            color: "primary",
          }}
          value={rows}
          onChange={handleRowChange}
        />
        <TextField
          required={true}
          type="number"
          id="columns"
          label="columns"
          InputProps={{ readOnly: false }}
          InputLabelProps={{
            shrink: true,
            position: "above",
            color: "primary",
          }}
          value={columns}
          onChange={handleColumnChange}
        />
      </Box>
      <Box sx={{ display: "inline-flex", gap: "8px", margin: "10px" }}>
        <Button
          type="submit"
          sx={{ backgroundColor: "blue" }}
          // disabled={true}
          // data-my-value={`[_.uid, _.id]`}
          variant="contained"
          color="secondary"
        >
          Create
        </Button>
        <Button
          sx={{ backgroundColor: "red" }}
          // data-my-value={[_.uid, _.id]}
          variant="contained"
          color="primary"
          onClick={handleClickOnBack}
        >
          Back
        </Button>
      </Box>
      <Box
        sx={{
          margin: "10px",
          backgroundColor: "white",
          borderRadius: "5px",
          display: "flex",
        }}
      >
        {!saving ? (
          <Button
            sx={{ backgroundColor: "green", display: displaySave }}
            variant="contained"
            color="primary"
            onClick={handleClickOnSave}
          >
            {" "}
            Save
          </Button>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </form>
  );
}
