import React from "react";
import { Spinner, Map } from "../../Components";
import { useLoading } from "../../Hooks";
function Maps() {
  let [isLoading] = useLoading(false);

  return (
    <>
      {isLoading ? (
        <div className="flex min-h-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <Map></Map>
      )}
    </>
  );
}

export default Maps;
