import * as React from "react";
import { Tooltip, IconButton } from "@mui/material";
import RoundedCornerTwoToneIcon from "@mui/icons-material/RoundedCornerTwoTone";
export default function MoveMultiplePoints() {
  return (
    <Tooltip title="Move multiple Points">
      <IconButton
        // onClick={handleAddPolygonButton}
        sx={{
          "&:hover": { bgcolor: "red" }
        }}
        aria-label="Move multiple Points"
      >
        <RoundedCornerTwoToneIcon sx={{ color: "black" }} />
      </IconButton>
    </Tooltip>
  );
}
