// const URL = "http://map.mon-agri.org/api/v1/";
// const URL = "http://localhost:8001/api/v1/";
// const URL = "http://64.226.78.25/backend/api/v1/";
// const URL = "https://api-dev.bugmapper.com.tr/api/v1/"
const URL = process.env.REACT_APP_API_URL
const SELECTED_INSECT_KEY = "selected-insect";
const SELECTED_FIELD_ID = "selected-field";
const SUM = "sum";
const AVG = "avg";
const RATE = "changeRate";
const THIS_MONTH = "THIS_MONTH";
const LAST_MONTH = "LAST_MONTH";
const LAST_TWO_MONTHS = "LAST_TWO_MONTHS";
const THREE_MONTHS = "THREE_MONTHS";
const SIX_MONTHS = "SIX_MONTHS";
const THIS_YEAR = "THIS_YEAR";
const LAST_YEAR = "LAST_YEAR";
const TWO_YEARS = "TWO_YEARS";
const FOUR_YEARS = "FOUR_YEARS";
export {
    URL,
    SELECTED_FIELD_ID,
    SELECTED_INSECT_KEY,
    SUM,
    AVG,
    RATE,
    THIS_MONTH,
    LAST_MONTH,
    THREE_MONTHS,
    SIX_MONTHS,
    THIS_YEAR,
    LAST_YEAR,
    TWO_YEARS,
    FOUR_YEARS,
    LAST_TWO_MONTHS,
};     