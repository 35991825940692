import classNames from "classnames";
import { title } from "process";
import React from "react";
import { useSelector } from "react-redux";
import { Images } from "../Assets";
import { Colors, isEmpty } from "../Utils";
import Image from "./Image";
import Label from "./Label";

function FieldCard({
  title = "",
  district = "",
  city = "",
  percentage = "",
  status = "",
  count = "",
  company_id = null,
  color = Colors.PRIMARY_DARK,
  colorInner = Colors.PRIMARY,
  selected = false,
  size = "w-4/10",
  padding = "p-1",
  field = {},
  onClick,
}) {
  let fieldsData = useSelector((state) => state.Fields.fieldsData);
  const appSettingsState = useSelector((state) => state.AppSettings);
  let colors = useSelector((state) => state.Fields.districtColors);

  let fieldData = fieldsData.filter((data) => data.uId === field.uid);
  color = colors.filter((data) => data.company_id === company_id)[0].color;
  
  return (
    <div
      className={classNames([size], [padding], "flex")}
      onClick={() => onClick(field)}
    >
      <div
        className={classNames(
          "p-5",
          "border-4",
          "rounded-xl",
          { border: `border-`[color] },
          { background: `border-`[colorInner] },
          "cursor-pointer",
          "text-center",
          "min-h-full",
          "align-bottom",
          "w-full"
        )}
        style={{
          borderColor: color,
          backgroundColor: selected && colorInner,
        }}
      >
        <Label
          size="text-lg"
          text={fieldData[0]?.count?.toFixed(2)}
          color={selected ? "#fff" : "#000"}
        />
        <br />
        <div className="flex flex-col flex-end">
          <Label size="text-2xl" className="mt-1" text={title} color="#000" />
          <div>
            <Label size="text-lg" text={district + ", "} color="#000" />
            <Label size="text-lg" text={city + ""} color="#000" />
          </div>
          {!appSettingsState.initialLoad && !isEmpty(fieldData[0]?.changeRate) &&
            fieldData[0]?.changeRate !== 0 && (
              <>
                <Label size="text-md" text={"Current Status:"} color={"#000"} />
                <div className="flex flex-wrap">
                  <div className="w-6/12 flex flex-col justify-center">
                    <Label
                      text={-fieldData[0]?.changeRate?.toFixed(2) + " %"}
                      color={fieldData[0]?.changeRate < 0 ? "red" : "green"}
                      size="text-2xl"
                    />
                  </div>
                  <div className="w-6/12 flex justify-center">
                    <Image
                      src={
                        fieldData[0]?.changeRate < 0
                          ? Images.red_arrow
                          : Images.green_arrow
                      }
                      alt="Arrow"
                      height="50px"
                      width="35px"
                    />
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
}

export default FieldCard;
