const PointsEvents = {
  state: {
    currentLatLongOnCreate: null,
  },
  reducers: {
    // To change the state fields
    setCurrentLatLongOnCreate: (state, payload) => {
      return {
        ...state,
        currentLatLongOnCreate: payload,
      };
    },
  },
  effcets: (dispatch) => ({}),
};

export default PointsEvents;
