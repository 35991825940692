import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableComponents } from "../../Components";

import { fetchByDotOperator } from "../../Components/Grid/ObjectFunctions";
import { GetNestedObjectString } from "../../Components/Grid/ObjectFunctions";
import _ from "lodash";

export default function Table() {
  let appSettings = useSelector((state) => state.AppSettings);
  let Fields = useSelector((state) => state.Fields);
  let singleSelectedData = useSelector(
    (state) => state.Fields.selectedData.single
  );
  let [singleLocalData, setSingleLocalData] = useState([]);
  let [dualLocalData, setDualLocalData] = useState([]);
  let dualSelectedData = useSelector((state) => state.Fields.selectedData.dual);
  let singleData = useSelector((state) => state.Fields.locationsData.single);
  let dualData = useSelector((state) => state.Fields.locationsData.dual);
  let disptach = useDispatch();
  let [selectSingle, setSingleSelect] = useState(["none"]);
  let [sortSingle, setSingleSort] = useState(false);
  let [selectDual, setDualSelect] = useState(["none"]);
  let [sortDual, setDualSort] = useState(false);
  useEffect(() => {
    setSingleLocalData(singleData);
    setDualLocalData(dualData);
  }, [singleData, dualData]);
  const columns = [
    {
      width: "14%",
      name: "No.",
      columnName: "location_no",
      type: "label",
    },
    {
      width: "17%",
      name: "Sum",
      columnName: "sum",
      type: "label",
    },
    {
      width: "17%",
      name: "Avg",
      columnName: "avg",
      type: "label",
    },
    {
      width: "17%",
      name: "Rate",
      columnName: "changeRate",
      type: "label",
    },
    {
      width: "35%",
      name: "Location",
      columnName: "title",
      type: "label",
    },
  ];

  const handleClickSingle = (field, type, object_name = ["insects"]) => {
    const data = singleData;
    let sortedData = [];
    if (type === "object") {
      sortedData = _.orderBy(
        data,
        (obj) => {
          return fetchByDotOperator(
            obj,
            GetNestedObjectString(object_name) + field
          );
        },
        [sortSingle ? "asc" : "desc"]
      );
    } else {
      sortedData = _.orderBy(data, [field], [sortSingle ? "asc" : "desc"]);
    }
    setSingleSort((state) => !state);
    setSingleSelect([field]);
    setSingleLocalData([...sortedData]);
  };
  const handleClickDual = (field, type, object_name = ["insects"]) => {
    const data = dualData;
    let sortedData = [];
    if (type === "object") {
      sortedData = _.orderBy(
        data,
        (obj) => {
          return fetchByDotOperator(
            obj,
            GetNestedObjectString(object_name) + field
          );
        },
        [sortDual ? "asc" : "desc"]
      );
    } else {
      sortedData = _.orderBy(data, [field], [sortDual ? "asc" : "desc"]);
    }
    setDualSort((state) => !state);
    setDualSelect([field]);
    setDualLocalData([...sortedData]);
  };

  return (
    <>
      <div className="text-center">
        <h1>{Fields?.fieldDataName}</h1>
      </div>
      <div className="flex flex-wrap">
        {appSettings.selectedMode !== "single" && (
          <div className="w-6/12">
            <div>
              <table
                className="table-auto border-separate border-spacing-1"
                style={{ width: "98%", overflow: "hidden" }}
              >
                <tr className="text-center text-sm bg-amber-400 text-white">
                  {columns.map((column) => (
                    <TableComponents.ColumnHeader
                      width={column.width}
                      name={column.name}
                      sort={sortDual}
                      select={selectDual}
                      columnName={column.columnName}
                      type={column.type}
                      onClick={(name, type) => {
                        handleClickDual(name, type);
                      }}
                    />
                  ))}
                </tr>
              </table>
            </div>
            <div className="overflow-x-auto" style={{ height: "65%" }}>
              <table
                className="table-auto border-separate border-spacing-1"
                style={{ width: "100%" }}
              >
                <tr className="text-center text-sm bg-amber-400 text-white p-1 hidden">
                  {columns.map((column) => (
                    <TableComponents.ColumnHeader
                      width={column.width}
                      name={column.name}
                      sort={sortDual}
                      select={selectDual}
                      columnName={column.columnName}
                      onClick={(name, type) => {
                        handleClickDual(name, type);
                      }}
                    />
                  ))}
                </tr>

                {dualLocalData.map((d) => (
                  <tr
                    className="text-right text-md bg-gray-100 border-white"
                    onClick={() => {
                      disptach.Fields.AddSelectedData({
                        type: "dual",
                        data: {
                          id: d.location_no,
                          index: dualData.findIndex(
                            (a) => a.location_no === d.location_no
                          ),
                        },
                      });
                    }}
                    style={
                      dualSelectedData?.some((a) => a.id === d.location_no)
                        ? { backgroundColor: "#d1d5db" }
                        : { backgroundColor: "#f3f4f6" }
                    }
                  >
                    <td style={{ width: "14%" }}>{d.location_no}</td>
                    <td style={{ width: "17%" }}>{d.sum}</td>
                    <td style={{ width: "17%" }}>{d.avg}</td>
                    <td
                      style={
                        d.changeRate > 0
                          ? { color: "red", width: "17%" }
                          : { color: "green", width: "17%" }
                      }
                    >
                      {d.changeRate} %
                    </td>
                    <td style={{ width: "35%" }}>{d.title}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        )}
        <div
          className={
            appSettings.selectedMode !== "single" ? "w-6/12" : "w-full"
          }
        >
          <div>
            <table
              className="table-auto border-separate border-spacing-1"
              style={{ width: "98%" }}
            >
              <tr className="text-center text-sm bg-amber-400 text-white">
                {columns.map((column) => (
                  <TableComponents.ColumnHeader
                    width={column.width}
                    name={column.name}
                    sort={sortSingle}
                    select={selectSingle}
                    columnName={column.columnName}
                    type={column.type}
                    onClick={(name, type) => {
                      handleClickSingle(name, type);
                    }}
                  />
                ))}
              </tr>
            </table>
          </div>
          <div className="overflow-x-auto" style={{ height: "65%" }}>
            <table
              className="table-auto border-separate border-spacing-1"
              style={{ width: "100%" }}
            >
              <tr className="text-center text-sm bg-amber-400 text-white hidden">
                {columns.map((column) => (
                  <TableComponents.ColumnHeader
                    width={column.width}
                    name={column.name}
                    sort={sortSingle}
                    select={selectSingle}
                    columnName={column.columnName}
                    type={column.type}
                    onClick={(name, type) => {
                      handleClickSingle(name, type);
                    }}
                  />
                ))}
              </tr>
              {singleLocalData.map((d) => (
                <tr
                  style={
                    singleSelectedData?.some((a) => a.id === d.location_no)
                      ? { backgroundColor: "#d1d5db" }
                      : { backgroundColor: "#f3f4f6" }
                  }
                  className="text-right text-md border-white"
                  onClick={() => {
                    disptach.Fields.AddSelectedData({
                      type: "single",
                      data: {
                        id: d.location_no,
                        index: singleData.findIndex(
                          (a) => a.location_no === d.location_no
                        ),
                      },
                    });
                  }}
                >
                  <td style={{ width: "14%" }}>{d.location_no}</td>
                  <td style={{ width: "17%" }}>{d.sum}</td>
                  <td style={{ width: "17%" }}>{d.avg}</td>
                  <td
                    style={
                      d.changeRate > 0
                        ? { color: "red", width: "17%" }
                        : { color: "green", width: "17%" }
                    }
                  >
                    {d.changeRate} %
                  </td>
                  <td style={{ width: "35%" }}>{d.title}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
