import axios from "axios";
import { Constants } from "../Utils";
import { get } from "./BaseFunctions";

const getMyFields = (filter) => {
  return get(`field-config/get-fields-of-a-user?company_sort=true&${filter}`);
};

const getLatestDatesOfAField = (fieldUId) => {
  return get(`field/get-latest-dates-of-a-field?fieldId=${fieldUId}`);
};

const getInsectCountOfAField = async (fieldUId, startDate, endDate, insect) => {
  let res = await axios.get(
    `${Constants.URL}field/get-insect-count-of-a-field?fieldUId=${fieldUId}&startDate=${startDate}&endDate=${endDate}&insect=${insect}`
  );
  return res;
};

const getDateRangesData = async (fieldUId, startDate, endDate, insect) => {
  let res = await axios.get(
    `${Constants.URL}field/get-insect-incr-in-a-field?fieldUId=${fieldUId}&startDate=${startDate}&endDate=${endDate}&insect=${insect}`
  );
  return res;
};

const getPlantCount = async (fieldUId, startDate, endDate, diseaseId) => {
  let res = await axios.get(
    `${Constants.URL}field/get-plant-count?fieldUId=${fieldUId}&startDate=${startDate}&endDate=${endDate}&diseaseId=${diseaseId}`
  );
  return res;
};

const getDateOfAField = (fieldUId, currentDate, dateType, operation) => {
  return get(
    `field/get-date?fieldUId=${fieldUId}&currentDate=${currentDate}&dateType=${dateType}&operation=${operation}`
  );
};

const getDatesOfAField = (fieldUId, startDate, endDate, operation) => {
  return get(
    `field/get-dates?fieldUId=${fieldUId}&startDate=${startDate}&endDate=${endDate}&operation=${operation}`
  );
};

const getPreviousDiseasesDates = (fieldUId, plantDiseaseId) => {
  return get(
    `plant/get-previous-dates/${fieldUId}/${plantDiseaseId}`
  );
};

const getPlantEntryDate = (fieldUId, plantDiseaseId) => {
  return get(
    `plant/get-latest-entry-date/${fieldUId}/${plantDiseaseId}`
  );
};

const getDatesOfAFieldAsync = async (
  fieldUId,
  startDate,
  endDate,
  operation
) => {
  let res = await get(
    `field/get-dates?fieldUId=${fieldUId}&startDate=${startDate}&endDate=${endDate}&operation=${operation}`
  );
  return res;
};
const checkDateOfAField = (fieldUId, currentDate, dateType) => {
  return get(
    `field/check-date-of-a-field?fieldUId=${fieldUId}&currentDate=${currentDate}&dateType=${dateType}`
  );
};

const checkHasDate = (fieldUId, currentDate, dateType, operator) => {
  return get(
    `field/check-has-date?fieldUId=${fieldUId}&currentDate=${currentDate}&dateType=${dateType}&operator=${operator}`
  );
};

const getSeasonDate = (fieldUid) => {
  return get(
    `plant/get-season-date/${fieldUid}`
  );
};

const getStatsOfLocationInAField = (
  fieldUId,
  nextStartDate,
  nextEndDate,
  prevStartDate,
  prevEndDate,
  insect,
  disease,
  mode
) => {
  return get(
    `field/get-stats-of-locations-in-a-field?fieldUId=${fieldUId}&nextStartDate=${nextStartDate}&nextEndDate=${nextEndDate}&insect=${insect ?? ''}&mode=${mode}&disease=${disease?.value || ''}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
  );
};

const getCitiesAndDistricts = () => {
  return get(`field/get-cities-and-districts`);
};

const getUserCompanies = () => {
  return get(`company/advisor`);
};

const getPlantDiseases = () => {
  return get(`plant/diseases`);
};

export {
  getMyFields,
  getLatestDatesOfAField,
  getInsectCountOfAField,
  getDateOfAField,
  getDatesOfAField,
  checkDateOfAField,
  getDatesOfAFieldAsync,
  getDateRangesData,
  checkHasDate,
  getStatsOfLocationInAField,
  getCitiesAndDistricts,
  getUserCompanies,
  getPlantDiseases,
  getSeasonDate,
  getPlantCount,
  getPreviousDiseasesDates,
  getPlantEntryDate
};
