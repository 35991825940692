import { BaseRoutes } from "./Routes";

import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import React, { useEffect } from "react";

function App() {
  let dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch.Auth.Login({
        token: localStorage.getItem("access_token"),
        user: localStorage.getItem("user"),
      });
    } else {
      dispatch.Auth.Logout();
    }
  }, []);

  return (
    <>
      <ToastContainer theme="colored" />
      <BaseRoutes />
    </>
  );
}

export default App;
