import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";

import Slider from "@mui/material/Slider";

export default function TimeLine() {
  let option = {
    chart: {
      type: "rangeBar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      type: "datetime",
      position: "top",
    },
  };

  let appSettings = useSelector((state) => state.AppSettings);
  let Fields = useSelector((state) => state.Fields);
  let singleSelectedData = useSelector(
    (state) => state.Fields.selectedData.single
  );
  let dualSelectedData = useSelector((state) => state.Fields.selectedData.dual);
  let singleData = useSelector((state) => state.Fields.locationsData.single);
  let dualData = useSelector((state) => state.Fields.locationsData.dual);
  let dispatch = useDispatch();
  let [nextSeries, setNextSeries] = useState([]);
  let [prevSeries, setPrevSeries] = useState([]);

  let [nextOptions, setNextOptions] = useState(option);
  let [prevOptions, setPrevOptions] = useState(option);

  const [prevValue, setPrevValue] = useState([1, 1]);
  const [nextValue, setNextValue] = useState([1, 1]);

  useEffect(() => {
    let newOption = {
      ...option,
      title: {
        text: `${Fields?.fieldDataName}`,
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
    };
    setNextOptions(newOption);
    setPrevOptions(newOption);
  }, [Fields?.fieldDataName]);

  useEffect(() => {
    if (singleData.length > 0)
      if (singleData[0].timelineData.length > 0)
        setNextValue([1, singleData[0].timelineData.length]);
    let singleArray = [];
    for (let i = 0; i < singleData.length; i++) {
      for (let j = 0; j < singleData[i]?.timelineData?.length; j++) {
        if (singleData[i]?.timelineData[j]?.sum !== 0)
          singleArray.push({
            x: singleData[i]?.location_no + "",
            y: [
              new Date(singleData[i]?.timelineData[j]?.startDate).getTime(),
              new Date(singleData[i]?.timelineData[j]?.endDate).getTime(),
              singleData[i]?.sum,
            ],
          });
      }
    }
    setNextSeries([
      {
        data: singleArray,
      },
    ]);
    setNextOptions((state) => {
      return {
        ...state,
        annotations: {
          xaxis: [
            {
              x: new Date(appSettings.dates.nextDate.startDate).getTime(),
              strokeDashArray: 0,
              borderColor: "#775DD0",
              label: {
                borderColor: "#775DD0",
                style: {
                  color: "#fff",
                  background: "#775DD0",
                  fontSize: "15px",
                },
                text: `Start Date ${appSettings.dates.nextDate.startDate}`,
              },
            },
            {
              x: new Date(appSettings.dates.nextDate.endDate).getTime(),
              strokeDashArray: 0,
              borderColor: "#FEB019",
              label: {
                borderColor: "#FEB019",
                style: {
                  color: "#fff",
                  background: "#FEB019",
                  fontSize: "15px",
                },
                text: `End Date ${appSettings.dates.nextDate.endDate}`,
              },
            },
          ],
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            var label = opts.w.globals.labels[opts.dataPointIndex];
            var a = moment(val[0]);
            var b = moment(val[1]);
            var diff = b.diff(a, "days");
            diff++;
            return diff + (diff > 1 ? " days" : " day");
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        // colors: [
        //   function (props) {
        //     if (singleSelectedData.some((a) => a.index === props.dataPointIndex)) {
        //       return "#FF0000";
        //     } else {
        //       return "#02DFDE";
        //     }
        //   },
        // ],
      };
    });
    if (appSettings.selectedMode !== "single") {
      let dualArray = [];
      for (let i = 0; i < dualData?.length; i++) {
        for (let j = 0; j < dualData[i]?.timelineData?.length; j++) {
          if (dualData[i]?.timelineData[j]?.sum !== 0)
            dualArray.push({
              x: dualData[i].location_no + "",
              y: [
                new Date(dualData[i]?.timelineData[j]?.startDate).getTime(),
                new Date(dualData[i]?.timelineData[j]?.endDate).getTime(),
                dualData[i].sum,
              ],
            });
        }
      }
      setPrevSeries([
        {
          data: dualArray,
        },
      ]);

      setPrevOptions((state) => {
        return {
          ...state,
          annotations: {
            xaxis: [
              {
                x: new Date(appSettings.dates.previousDate.startDate).getTime(),
                strokeDashArray: 0,
                borderColor: "#775DD0",
                label: {
                  borderColor: "#775DD0",
                  style: {
                    color: "#fff",
                    background: "#775DD0",
                    fontSize: "15px",
                  },
                  text: `Start Date ${appSettings.dates.previousDate.startDate}`,
                },
              },
              {
                x: new Date(appSettings.dates.previousDate.endDate).getTime(),
                strokeDashArray: 0,
                borderColor: "#FEB019",
                label: {
                  borderColor: "#FEB019",
                  style: {
                    color: "#fff",
                    fontSize: "15px",
                    background: "#FEB019",
                  },
                  text: `End Date ${appSettings.dates.previousDate.endDate}`,
                },
              },
            ],
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              var label = opts.w.globals.labels[opts.dataPointIndex];
              var a = moment(val[0]);
              var b = moment(val[1]);
              var diff = b.diff(a, "days");
              diff++;
              return diff + (diff > 1 ? " days" : " day");
            },
            style: {
              colors: ["#f3f4f5", "#fff"],
            },
          },
        };
      });

      if (dualData.length > 0)
        if (dualData[0].timelineData.length > 0)
          setPrevValue([1, dualData[0].timelineData.length]);
    }
  }, [singleData, dualData]);

  function valuetextNext(value) {
    return singleData[0].timelineData[value - 1].startDate;
  }

  function valuetextPrev(value) {
    return dualData[0].timelineData[value - 1].endDate;
  }

  return (
    <>
      <div className="flex flex-wrap">
        {appSettings.selectedMode !== "single" && (
          <div className="w-6/12">
            {dualData?.length > 0 ? (
              dualData[0]?.timelineData?.length > 0 ? (
                <div className="mx-4">
                  <label>
                    {dualData[0]?.timelineData[prevValue[0] - 1]?.startDate} -
                    {dualData[0]?.timelineData[prevValue[1] - 1]?.endDate}
                  </label>
                  <Slider
                    min={1}
                    max={
                      dualData.length > 0
                        ? dualData[0].timelineData.length > 0
                          ? dualData[0].timelineData.length
                          : 1
                        : 1
                    }
                    getAriaLabel={() => "Date range"}
                    value={prevValue}
                    onChange={(e, value) => {
                      setPrevValue(value);
                      if (dualData.length > 0)
                        if (dualData[0].timelineData.length > 0)
                          dispatch.AppSettings.SetPreviousDates({
                            startDate:
                              dualData[0].timelineData[prevValue[0] - 1]
                                .startDate,
                            endDate:
                              dualData[0].timelineData[prevValue[1] - 1]
                                .endDate,
                          });
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetextPrev}
                  />
                </div>
              ) : null
            ) : null}
            <Chart
              id="1"
              options={prevOptions}
              series={prevSeries}
              type="rangeBar"
              height={700}
            />
          </div>
        )}
        <div
          className={
            appSettings.selectedMode !== "single" ? "w-6/12" : "w-full"
          }
        >
          {singleData?.length > 0 ? (
            singleData[0]?.timelineData?.length > 0 ? (
              <div className="mx-4">
                <label>
                  {singleData[0]?.timelineData[nextValue[0] - 1]?.startDate} -
                  {singleData[0]?.timelineData[nextValue[1] - 1]?.endDate}
                </label>
                <Slider
                  min={1}
                  max={
                    singleData.length > 0
                      ? singleData[0].timelineData.length > 0
                        ? singleData[0].timelineData.length
                        : 1
                      : 1
                  }
                  getAriaLabel={() => "Date range"}
                  value={nextValue}
                  onChange={(e, value) => {
                    setNextValue(value);
                    if (singleData.length > 0)
                      if (singleData[0].timelineData.length > 0)
                        dispatch.AppSettings.SetNextDates({
                          startDate:
                            singleData[0].timelineData[nextValue[0] - 1]
                              .startDate,
                          endDate:
                            singleData[0].timelineData[nextValue[1] - 1]
                              .endDate,
                        });
                  }}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetextNext}
                />
              </div>
            ) : null
          ) : null}
          <Chart
            id="2"
            options={nextOptions}
            series={nextSeries}
            type="rangeBar"
            height={700}
          />
        </div>
      </div>
    </>
  );
}
