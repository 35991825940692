import { get, put, post, _delete } from "./BaseFunctions";

const getAllCompanies = () => {
  return get("company?limit=20&page=1");
};
const getfieldsByCompany = (company_id) => {
  return get(`field/get-fields-of-a-company?company_id=${company_id}`);
};

const getAllCoordinatesOfAfields = (field_uuid) => {
  return get(`field/get-all-locations-of-a-field?field_uid=${field_uuid}`);
};

const getAllPolygonsOfAfields = (field_id) => {
  return get(`polygon/${field_id}`);
};

const putPolygonField = (polygon_id, data) => {
  return put(`polygon/${polygon_id}`, data);
};

const putPointField = (data) => {
  return put(`field/update-location-points`, data);
};

const postPointField = (data) => {
  return post("field/create-location-points", data);
};

const postPolygonField = (data) => {
  return post("polygon", data);
};

const deletePolygonField = (polgyon_id) => {
  return _delete(`polygon/${polgyon_id}`);
};

const deletePointField = (point_id) => {
  return _delete(`field/delete-location-point/${point_id}`);
};

export {
  getAllCompanies,
  getfieldsByCompany,
  getAllCoordinatesOfAfields,
  getAllPolygonsOfAfields,
  putPointField,
  putPolygonField,
  postPointField,
  postPolygonField,
  deletePolygonField,
  deletePointField,
};
