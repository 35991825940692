import React from "react";
import { Home, Maps } from "../Pages/Protected";
import { Routes, Route, Navigate } from "react-router-dom";
import Table from "../Pages/Protected/Table";
import Charts from "../Pages/Protected/Charts";
import TimeLine from "../Pages/Protected/TimeLine";
import PPP from "../Pages/Protected/PPP";

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/auth/*" element={<Navigate to="/" />} />
      <Route index element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/maps" element={<Maps />} />
      <Route path="/tables" element={<Table />} />
      <Route path="/charts" element={<Charts />} />
      <Route path="/timeline" element={<TimeLine />} />
      <Route path="/ppp" element={<PPP />} />
    </Routes>
  );
};
export default ProtectedRoutes;
