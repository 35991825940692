import React, { useEffect } from "react";
import { Tooltip, IconButton } from "@mui/material";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { useSelector, useDispatch } from "react-redux";

export default function DeleteMultiplePoints({
  map,
  onDeletedMultiplePointsBoundary,
}) {
  const dispatch = useDispatch();

  const deletedMultiplePointsBoundary = useSelector(
    (state) => state.CreatePointandPolygon.deletedMultiplePointsBoundary
  );

  useEffect(() => {
    if (deletedMultiplePointsBoundary) {
      const polygon_ = new window.google.maps.Polygon({
        paths: deletedMultiplePointsBoundary,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "green",
        fillOpacity: 0.35,
        editable: false,
        draggable: false,
        clickable: false,
        geodesic: false,
      });
      polygon_.setMap(map);
      onDeletedMultiplePointsBoundary(polygon_);
    }
  }, [deletedMultiplePointsBoundary, map]);

  const handleDeleteMultiplePointsButton = () => {
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      markerOptions: { draggable: true },
      polygonOptions: {
        fillColor: "#BCDCF9",
        fillOpacity: 0.5,
        strokeWeight: 2,
        strokeColor: "#57ACF9",
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    });
    drawingManager.setMap(map);

    // Add a polygoncomplete event listener to get the initial polygon geometry
    window.google.maps.event.addListener(
      drawingManager,
      "polygoncomplete",
      (p) => {
        drawingManager.setMap(null);
        p.setMap(null);
        const updatedCoords = p
          .getPaths()
          .getAt(0)
          .getArray()
          .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
        const lastItem = Object.assign({}, updatedCoords[0]); // create a copy of the first item
        updatedCoords.push(lastItem); // add the copy as the last item in the array
        dispatch.CreatePointandPolygon.setDeletedMultiplePointsBoundary(
          updatedCoords
        );
        dispatch.DisplaySettings.toggleEditButtons(false);
      }
    );
  };

  return (
    <Tooltip title="Delete multiple Points">
      <IconButton
        onClick={handleDeleteMultiplePointsButton}
        sx={{
          "&:hover": { bgcolor: "red" },
        }}
        aria-label="Delete multiple Points"
      >
        <HighlightAltIcon sx={{ color: "black" }} />
      </IconButton>
    </Tooltip>
  );
}
